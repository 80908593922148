import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Mentions, Button, message } from 'antd';
import { getQuestionAnswerByNatureTrailId, saveQuestionAnswer } from '../../actions/question';

const mapDispatch = (dispatch) => {
    return {
        getQuestionAnswerByNatureTrailId: (natureTrailId) => dispatch((getQuestionAnswerByNatureTrailId(natureTrailId))),
        saveQuestionAnswer: (questionAnswer) => dispatch(saveQuestionAnswer(questionAnswer))
    }
}

class ConnectedOneQuestionOneAnswer extends Component {
    state = {
        key: undefined,
        actualQuestion: "",
        actualQuestionCharacterLimit: 1500,
        actualAnswer: "",
        actualAnswerCharacterLimit: 1500,
        goodAnswerMessage: "",
        goodAnswerMessageLimit: 150,
        badAnswerMessage: "",
        badAnswerMessageLimit: 150,
    }

    componentDidMount = async () => {
        if (this.props.naturalTrailLocationId !== undefined) {
            await this.props.getQuestionAnswerByNatureTrailId(this.props.naturalTrailLocationId);

            if (this.props.status === "loaded" && this.props.questionAnswerByLocation[0] !== undefined) {
                this.setState({
                    key: this.props.questionAnswerByLocation[0].key,
                    actualQuestion: this.props.questionAnswerByLocation[0].question,
                    actualAnswer: this.props.questionAnswerByLocation[0].answer,
                    goodAnswerMessage: this.props.questionAnswerByLocation[0].goodAnswerMessage,
                    badAnswerMessage: this.props.questionAnswerByLocation[0].wrongAnswerMessage,
                })
            }
        }
    }

    changeGoodAnswerMessage = (event) => {
        const actualValue = event
        if (actualValue.length <= this.state.goodAnswerMessageLimit) {
            this.setState({ goodAnswerMessage: event })
        } else {
            message.error("Az üzenet karakterszáma maximum " + this.state.goodAnswerMessageLimit + " lehet.")
        }
    }

    changeBadAnswerMessage = (event) => {
        const actualValue = event
        if (actualValue.length <= this.state.badAnswerMessageLimit) {
            this.setState({ badAnswerMessage: event })
        } else {
            message.error("Az üzenet karakterszáma maximum " + this.state.badAnswerMessageLimit + " lehet.")
        }
    }

    changeQuestion = (event) => {
        const actualValue = event
        if (actualValue.length <= this.state.actualQuestionCharacterLimit) {
            this.setState({ actualQuestion: event })
        } else {
            message.error("A kérdés karakterszáma maximum " + this.state.actualQuestionCharacterLimit + " lehet.")
        }
    }

    changeAnswer = (event) => {
        const actualValue = event
        if (actualValue.length <= this.state.actualQuestionCharacterLimit) {
            this.setState({ actualAnswer: event })
        } else {
            message.error("A válasz karakterszáma maximum " + this.state.actualAnswerCharacterLimit + " lehet.")
        }
    }

    saveQuestionAnswer = async () => {
        const saveObj = {
            key: this.state.key,
            natureTrailLocationId: this.props.naturalTrailLocationId,
            question: this.state.actualQuestion.trim(),
            answer: this.state.actualAnswer.trim(),
            goodAnswerMessage: this.state.goodAnswerMessage.trim(),
            wrongAnswerMessage: this.state.badAnswerMessage.trim(),
        }
        if (this.state.actualQuestion.length > this.state.actualQuestionCharacterLimit) {
            message.error("A kérdés karakterszáma maximum" + this.state.actualQuestionCharacterLimit + " lehet.")
        }
        else if (this.state.actualAnswer.length > this.state.actualAnswerCharacterLimit) {
            message.error("A választ karakterszáma maximum" + this.state.actualAnswerCharacterLimit + " lehet.")
        } else {
            await this.props.saveQuestionAnswer(saveObj);
            if (this.props.saveStatus === "saved" && this.props.questionAnswer !== undefined) {
                await this.props.getQuestionAnswerByNatureTrailId(this.props.naturalTrailLocationId);
                this.setState({
                    key: this.props.questionAnswer.key,
                    actualQuestion: this.props.questionAnswer.questionText,
                    actualAnswer: this.props.questionAnswer.questionAnswer
                })
                this.props.setQuestionAnswerId(this.props.questionAnswer.key)
                message.success("Sikeres mentés")
            }
            else {
                message.error("Hiba a mentés során")
            }
        }
    }



    render() {
        return (
            <div>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                    <h3>Kérdés</h3>
                </div>
                <Mentions rows="10"
                          title="Kérdés"
                          value={this.state.actualQuestion}
                          onChange={this.changeQuestion}
                >
                </Mentions>
                <p><strong>Hátralévő
                    karekterek: </strong>{this.state.actualQuestionCharacterLimit}/{this.state.actualQuestionCharacterLimit - this.state.actualQuestion.length}
                </p>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                    <h3>Válasz</h3>
                </div>
                <Mentions rows="10"
                          title="Válasz"
                          value={this.state.actualAnswer}
                          onChange={this.changeAnswer}
                >
                </Mentions>
                <p><strong>Hátralévő
                    karekterek: </strong>{this.state.actualAnswerCharacterLimit}/{this.state.actualAnswerCharacterLimit - this.state.actualAnswer.length}
                </p>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                    <h3>Üzenet helyes válasz esetén</h3>
                </div>
                <Mentions rows="1"
                          title="Üzenet helyes válasz esetén"
                          value={this.state.goodAnswerMessage}
                          onChange={this.changeGoodAnswerMessage}>
                </Mentions>
                <p><strong>
                    Hátralévő
                    karekterek: </strong>{this.state.goodAnswerMessageLimit}/{this.state.goodAnswerMessageLimit - this.state.goodAnswerMessage.length}
                </p>

                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                    <h3>Üzenet helytelen válasz esetén</h3>
                </div>
                <Mentions rows="1"
                          title="Üzenet helytelen válasz esetén"
                          value={this.state.badAnswerMessage}
                          onChange={this.changeBadAnswerMessage}>
                </Mentions>
                <p><strong>
                    Hátralévő
                    karekterek: </strong>{this.state.badAnswerMessageLimit}/{this.state.badAnswerMessageLimit - this.state.badAnswerMessage.length}
                </p>

                <Button onClick={this.saveQuestionAnswer}>Mentés/Hozzáadás</Button>
            </div>
        )
    }
}

const mapState = (state) => {
    return {
        questionAnswerByLocation: state.questionReducer.questionAnswerByLocation,
        questionAnswer: state.questionReducer.questionAnswer,
        status: state.questionReducer.status,
        saveStatus: state.questionReducer.saveStatus,
        //questionAnswerByLocation
    }
}

const OneQuestionOneAnswer = connect(mapState, mapDispatch)(ConnectedOneQuestionOneAnswer)
export default OneQuestionOneAnswer;
